// import { ISignUpResponse } from '@klikkie/klikkie-schemas/lib/interfaces';
import { createAction, createStandardAction } from 'typesafe-actions';

import types from './types';

export const loginRequest = createAction(
  types.LOGIN_REQUEST,
  resolve => () => resolve(),
);

export const loginOk = createAction(
  types.LOGIN_OK,
  // resolve => (signupResponse: ISignUpResponse) => resolve({ signupResponse }),
  // TODO: type this
  resolve => (signupResponse: any) => resolve({ signupResponse }),
);

export const loginCheck = createAction(
  types.LOGIN_CHECK,
  resolve => () => resolve({}),
);

export const authOk = createAction(types.AUTH_OK);

export const sessionError = createStandardAction(types.ERROR).map(
  (error: Error) => ({
    error: true,
    payload: error,
  }),
);
