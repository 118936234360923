import React, {
  useState, FunctionComponent, ReactNode, useEffect, Suspense,
} from 'react';

import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/fr';

import i18n, { changeLanguage as i18nextChangeLanguage } from 'state/services/i18n';
import { I18nextProvider } from 'react-i18next';
import { Preloader } from 'views/components';
import { languageSelector } from 'state/modules/user/selectors';
import { useSelector } from 'react-redux';

interface Props {
  initialLanguage?: string;
  children: ReactNode;
}

// TODO: Move login to redux
const TranslationProvider: FunctionComponent<Props> = (props: Props) => {
  const { children, initialLanguage } = props;
  const [language, setLanguage] = useState('');
  const appLanguage = useSelector(languageSelector) || initialLanguage || 'nl-NL';

  const changeLanguage = async (newLanguage: string): Promise<void> => {
    try {
      await i18nextChangeLanguage(newLanguage);

      moment.locale(newLanguage);
      setLanguage(newLanguage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    changeLanguage(appLanguage);
  });

  if (appLanguage !== language) {
    changeLanguage(appLanguage);
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Preloader />}>
        {children}
      </Suspense>
    </I18nextProvider>
  );
};

TranslationProvider.defaultProps = {
  initialLanguage: 'nl-NL',
};

export default TranslationProvider;
