import { isActionOf } from 'typesafe-actions';
import { filter, mergeMap } from 'rxjs/operators';

import * as userActions from 'state/modules/user/actions';
import * as uiActions from 'state/modules/ui/actions';
import * as actions from './actions';

export const {
  setAppNotification,
  deleteAppNotification,
  loadingStart,
  loadingEnd,
} = uiActions;

export const appLoadingStart: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(userActions.getUser)),
    mergeMap(() => ([
      actions.loadingStart(),
    ])),
  );
};

export const appLoadingEnd: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf([
      userActions.setUser,
      actions.setAppNotification,
      userActions.userError,
    ])),
    mergeMap(() => ([
      actions.loadingEnd(),
    ])),
  );
};

export const epics = [
  appLoadingStart,
  appLoadingEnd,
];
