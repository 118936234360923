import numeral from 'numeral';
import { Currencies, CountryCode } from '@klikkie/klikkie-schemas/lib/interfaces';

/**
 * Takes a value and the format string from locize and formats it accordingly
 * Supports endless divide and multiply
 *
 * Example params: value: 500, formatString: "€0.0,[00] divide:100"
 * First the value is divided by 100 => 5, then it is formatted using "€0.0,[00]" resulting in 5.00
 *
 *
 * @param value
 * @param formatString
 */
// eslint-disable-next-line import/prefer-default-export
export const formatCurrency = (value: number, formatString: string): string => {
  let numberNumeral = numeral(value);

  const [format, ...extraOptions] = formatString.split(' ');

  extraOptions.forEach((option) => {
    const [operator, operand] = option.split(':');

    if (operator === 'divide') {
      numberNumeral = numberNumeral.divide(Number(operand));
    }

    if (operator === 'multiply') {
      numberNumeral = numberNumeral.multiply(Number(operand));
    }
  });

  return numberNumeral.format(format);
};

/**
 * Takes a number representing a price and formats it as a string that shows a decimal;
 * Example params: 9900
 * Example return value: 99.00
 *
 * @param price
 */
export const parsePrice = (price: number) => {
  const parsedPrice = typeof price === 'number' ? formatCurrency(price, '€0.0,[00] divide:100') : price;

  return parsedPrice;
};

/**
 * Takes a country code, a currency and number representing a price and
 * formats it as a string that shows a decimal and a currency symbol;
 * Example € 94,95 or 94,95 €
 * @param countryCode
 * @param currency
 * @param price
 */
export const formatPriceAndCurrency = (
  countryCode: CountryCode,
  currency: Currencies,
  price: number,
) => {
  const formatedPriceAndCurrency = new Intl.NumberFormat(
    countryCode,
    {
      style: 'currency',
      currency,
    },
  ).format(price);

  return formatedPriceAndCurrency;
};
