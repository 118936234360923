import * as picturesOperations from './operations';
import * as selectors from './selectors';
import reducers, { picturesInitialState } from './reducers';
import types from './types';

export {
  selectors,
  types,
  picturesOperations,
  picturesInitialState,
};

export default reducers;
