import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import LocizeBackend from 'i18next-locize-backend';

import { date, currency, string } from '../../utils';

const NUMERAL_PREFIX = 'numerall___';

/**
 * Locize options and constants
 *
 * Options regarding languages and namespaces should be set in i18next options
 */
const localStorageBackendOptions = {
  // prefix for stored languages
  prefix: 'i18next_res_',

  // expiration
  // 30 minutes
  expirationTime: process.env.REACT_APP_LOCIZE_VERSION === 'latest' ? 30 * 60 * 1000 : 1,

  // language versions
  versions: process.env.REACT_APP_LOCIZE_VERSION,

  // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
  store: window.localStorage,
};

/**
 * Locize options and constants
 *
 * Options regarding languages and namespaces should be set in i18next options
 */
const locizeBackendOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

/**
 * Formats the productType based on the JSON definition in common namespace
 * Will return the given value if the productType is not translated
 *
 * @param productType
 */
const formatProductType = (productType: string): string => {
  const productTypesJSON = i18next.t('common:productTypes');

  const productTypesObject = productTypesJSON !== 'productTypes' ? JSON.parse(productTypesJSON) : {};

  if (productTypesObject[productType]) {
    return productTypesObject[productType];
  }

  return productType;
};

/**
 * i18next options
 *
 * Escaping is disabled to make links work
 */
const i18nOptions: i18next.InitOptions = {
  fallbackLng: {
    default: ['en', 'nl-NL', 'nl-BE', 'fr-FR'],
    'nl-BE': ['nl-NL'],
  },
  appendNamespaceToCIMode: true,
  ns: ['share-app', 'address', 'common'],
  defaultNS: 'share-app',
  load: 'all',
  backend: {
    backends: [
      LocalStorageBackend, // primary
      LocizeBackend, // fallback
    ],
    backendOptions: [localStorageBackendOptions, locizeBackendOptions],
  },
  interpolation: {
    escapeValue: false,
    format: (rawValue, formatType?: string, lng?: string): string => {
      if (formatType) {
        if (formatType === 'formatDate') {
          return date.getLocaleFormattedDate(rawValue, lng);
        }

        if (formatType === 'formatMonth') {
          return date.getLocaleLongMonthFromTimestamp(rawValue, lng);
        }

        if (formatType.startsWith(NUMERAL_PREFIX)) {
          const formatString = string.removePrefix(formatType, NUMERAL_PREFIX);
          const value = Number(rawValue);

          return currency.formatCurrency(value, formatString);
        }

        if (formatType === 'formatProductType') {
          const value = `${rawValue}`;

          return formatProductType(value);
        }
      }

      return rawValue;
    },
  },
};
/**
 * Load i18next with Locize backend
 * Returns a promise
 *
 * @param language
 */
i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init(i18nOptions);

export const changeLanguage = (language: string = 'en') => new Promise((resolve, reject) => {
  i18next.changeLanguage(language, (error, t) => {
    if (error) {
      reject(error);
    }

    resolve(t);
  });
});

/**
 * Get the translation function given the language and optional namespace
 *
 *
 * @param language
 * @param namespace
 */
export const getTranslationFunction = (
  language: string,
  namespace?: string,
) => {
  if (namespace) {
    return i18next.getFixedT(language, namespace);
  }

  return i18next.getFixedT(language);
};

/**
 * Add a translation to use locally
 *
 * @param language
 * @param namespace
 * @param key
 * @param value
 */
export const addTranslation = (
  language: string,
  namespace: string,
  key: string,
  value: string,
): void => {
  i18next.addResource(language, namespace, key, value);
};

/**
 * Add an list of translation given as object to use locally
 *
 * Each translation is an entry in the object.
 * It calls addTranslation under the hood for every entry
 *
 * @param language
 * @param namespace
 * @param valuesObject
 */
export const addTranslations = (
  language: string,
  namespace: string,
  valuesObject: { [key: string]: string },
): void => {
  Object.entries(valuesObject)
    .forEach(([key, value]) => addTranslation(language, namespace, key, value));
};

/**
 * Get translation from i18next
 * If namespace is not specified, then it will load the default namespace
 *
 * @param key
 * @param namespace
 */
export const getTranslation = (
  key: string,
  options?: object,
): string => (i18next.t(key, options));

export default i18next;
