import React, { useEffect, useState, useRef } from 'react';
import { ProgressBar } from '..';

interface Props {
  item: number;
  activeItem: number;
  nextPage: () => void;
}

const StoryProgressBar = (props: Props) => {
  const {
    item, activeItem, nextPage,
  } = props;
  const [progress, setProgress] = useState<number>(0);
  const barInterval = useRef<any>();

  useEffect(() => {
    if (item === activeItem) {
      setProgress(0);
      barInterval.current = setInterval(() => {
        setProgress(prevProgress => prevProgress + 1);
      }, 5000 / 100);
    } else if (barInterval.current) {
      clearInterval(barInterval.current);
    }

    if (item > activeItem && progress > 0) {
      setProgress(0);
    } else if (item < activeItem && progress < 100) {
      setProgress(99);
    }
  }, [item, activeItem, progress]);

  useEffect(() => {
    if (progress >= 100) {
      if (barInterval.current) {
        clearInterval(barInterval.current);
      }
      nextPage();
    }
  }, [progress, nextPage]);

  return (
    <ProgressBar progress={progress} />
  );
};

export default StoryProgressBar;
