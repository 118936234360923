import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { middlewares, epicMiddleware } from './middlewares';
import { reducers, epics } from './modules';
import { RootState } from './rootState.d';


declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const composeEnhancers = (
  // eslint-disable-next-line no-underscore-dangle
  window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

const persistKey = 'klikkie1Share';

const persistConfig: PersistConfig = {
  key: persistKey,
  storage: storageSession,
};

const rootReducer = reducers;

const rootReducerWrapper = (state?: RootState, action?: any) => {
  // TODO: type this any
  const newState: any = { ...state };

  return rootReducer(newState, action);
};

export const configureStore = (initialState?: RootState | any) => {
  const persistedReducer = persistReducer(persistConfig, rootReducerWrapper);

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
  );

  return createStore(
    persistedReducer,
    initialState,
    enhancer,
  );
};

const store = configureStore();
export const persistor = persistStore(store);

// redux-observable epic middleware init
epicMiddleware.run(epics);

export default store;
