import { analytics } from 'state/services/firebase';

const handleGATracking = (eventCategory: string, eventLabel: string) => {
  window.dataLayer.push({
    event: 'event',
    eventCategory,
    eventAction: 'click',
    eventLabel,
  });
};

const handleFirebaseTracking = (event: string, data: any = {}) => analytics.logEvent(event, data);

export {
  handleGATracking,
  handleFirebaseTracking,
};
