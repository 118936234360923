/**
 * Remove line breaks from string.
 *
 * @param str
 * @return string without line breaks
 */
export const removeLineBreaks = (str: string): string => str.replace(/(\r\n\t|\n|\r\t)/gm, '');

/**
 * Removes the prefix by splitting based on the prefix
 *
 * Split is limited to 2 so the split doesn't remove other
 * The first value after split will always be empty
 * The returned value is always the second item in the array at index 1
 *
 * @param str
 * @param prefix
 */
export const removePrefix = (str: string, prefix: string): string => {
  if (str.startsWith(prefix)) {
    return str.slice(prefix.toString().length);
  }

  return str;
};

/**
 * Checks if the country code belongs to a EU subscription or to one of
 * the individually supported countries and replaces country codes to 'EU'
 *
 * @param countryCode
 */
export const checkCountryCode = (countryCode: string): string => {
  const countries = ['BE', 'GB', 'FR', 'NL'];
  const verifiedCountryCode = countries.includes(countryCode) ? countryCode : 'EU';

  return verifiedCountryCode;
};
