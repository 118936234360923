/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const prevArrow = require('views/assets/images/icons/prev-arrow.png');

interface PrevArrowProps {
  display?: boolean;
  prevPage: () => void;
}

const PrevArrow = (props: PrevArrowProps) => {
  const { display, prevPage } = props;

  if (!display) return null;

  return (
    <div className="slick-arrow prev-arrow" onClick={prevPage}>
      <img src={prevArrow} alt="prev-arrow" />
    </div>
  );
};

PrevArrow.defaultProps = {
  display: true,
};

export default PrevArrow;
