import React from 'react';

import './Preloader.scss';

const preloader = require('views/assets/images/loaders/loader.gif');

const Preloader = () => {
  return (
    <div className="Preloader">
      <img className="Preloader-image" src={preloader} alt="klikkie-preloader" />
    </div>
  );
};

export default React.memo(Preloader);
