import moment from 'moment';

/**
 * Date to iso standard
 *
 * @param date as ISOString
 * @param nodeEnv short way of node env
 */
export const dateToISOStandard = (date: string | number) => new Date(date).toISOString().split('T')[0];
export const nowToISOStandard = () => new Date().toISOString().split('T')[0];

/**
 * Get current month day
 *
 * @returns {number} returns base 0 so 0-6
 */
export const getCurrentWeekDay = () => new Date().getDay();

/**
 * Get current year
 *
 * @returns {number} current year
 */
export const getCurrentYear = (time: number = Date.now()) => {
  return new Date(time).getFullYear();
};

/**
 * Get current month
 *
 * @returns {number} base 0 so 0-11
 */
export const getCurrentMonth = (time: number = Date.now()) => {
  return new Date(time).getMonth();
};

/**
 * Get long month based on locale
 *
 * 'January' for locale:'en', monthIndex: 0
 *
 * @param locale
 * @param monthIndex
 */
export const getLocaleLongMonth = (
  monthIndex: number,
  locale: string,
): string => {
  const date = moment().set('month', monthIndex);
  date.locale(locale);

  return date.format('MMMM');
};

/**
 * Get long month based on locale
 *
 * 'January' for locale:'en', monthIndex: 0
 *
 * @param locale
 * @param monthIndex
 */
export const getLocaleLongMonthFromTimestamp = (
  timestamp: number,
  locale: string = 'en',
): string => {
  const date = moment(timestamp);
  date.locale(locale);

  return date.format('MMMM');
};

/**
 * Takes a timestamp and an optional locale string, and returns the date formatted for that locale
 *
 * rawTimestamp: 1546853398, locale: "en" => "07 Junuary 2019"
 *
 * @param rawTimestamp
 * @param locale
 */
export const getLocaleFormattedDate = (
  rawTimestamp: number | string,
  locale: string = 'en',
): string => {
  const timestamp = Number(rawTimestamp);
  const date = new Date(timestamp);

  const dayIndex = date.getDate();
  const monthIndex = date.getMonth();
  const yearIndex = date.getFullYear();

  const monthString = getLocaleLongMonth(monthIndex, locale);

  return `${dayIndex} ${monthString} ${yearIndex}`;
};

/**
 * Get current month day
 *
 * @returns {number} base 1 so 1-31
 */
export const getCurrentMonthDay = (
  date: string | number = Date.now(),
) => new Date(date).getUTCDate();

/**
 *
 * @param date
 */
export const getMonthDayFromMillis = (date: number): number => new Date(date).getDate();

/**
 * Return days in a given month
 *
 * @param {number} month
 * @param {number} year
 *
 * @returns {number} total days in a month
 */
export const getDaysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();

/**
 * Milliseconds in Gregorian months
 *
 * @param months
 */
export const millisecondInGregorianMonth = 2629746000;
export const millisecondInHalfGregorianMonth: number = millisecondInGregorianMonth / 2;

/**
 * Return the milliseconds per months.
 *
 * @param months
 */
export const months2milliseconds = (months: number): number => millisecondInGregorianMonth * months;

/**
 * From date to timestmap
 *
 * @param months
 */
export const date2Timestamp = (date: string | number): number => new Date(date).getTime();
