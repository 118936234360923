import { AnyAction, Dispatch } from 'redux';

const marker = () => {
  const timePoints: number[] = [];

  return {
    addMark: () => timePoints.push(new Date().getTime()),
    getLastMark: () => `${timePoints[timePoints.length - 1] - timePoints[timePoints.length - 2]}ms`,
  };
};

// eslint-disable-next-line max-len
const measureMiddleware = () => (next: Dispatch<AnyAction>) => <A extends AnyAction>(action: A) => {
  const marks = marker();
  // get millis
  marks.addMark();

  next(action);

  // get millis
  marks.addMark();

  // Logging adding CSS
  // eslint-disable-next-line no-console
  console.log(
    `%c ${action.type}: ${marks.getLastMark()}`,
    'background: #222; color: red; font-weight: 700;',
  );
};

export default measureMiddleware;
