import { AnyAction } from 'redux';
import { IUserInterface } from './ui.d';

import types from './types';

export const uiInitialState: IUserInterface = {
  language: 'nl-NL',
  isOnMaintenance: false,
  isLoading: false,
  appNotifications: {
    status: 'fulfilled',
    type: 'error',
    message: '',
  },
  userNotifications: [],
};

export default (state = uiInitialState, action: AnyAction): IUserInterface => {
  switch (action.type) {
    case types.APP_NOTIFICATION_SET:
      return {
        ...state,
        appNotifications: {
          ...action.payload,
        },
      };

    case types.APP_NOTIFICATION_DELETE:
      return {
        ...state,
        appNotifications: {
          ...state.appNotifications,
          status: 'fulfilled',
          message: '',
        },
      };

    case types.LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.LOADING_END:
      return {
        ...state,
        isLoading: false,
      };

    case types.LANGUAGE_SET:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
