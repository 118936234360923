export default {
  ERROR: '@k-share/pictures/ERROR',
  PICTURES_UPLOAD: '@k-share/pictures/PICTURES_UPLOAD',
  PICTURE_UPDATE: '@k-share/pictures/PICTURE_UPDATE',
  PICTURE_DELETE: '@k-share/pictures/PICTURE_DELETE',
  PICTURE_REMOVE: '@k-share/pictures/PICTURE_REMOVE',
  PICTURE_ERROR: '@k-share/pictures/PICTURE_ERROR',
  PICTURE_REJECTED: '@k-share/pictures/PICTURE_REJECTED',
  PICTURES_CLEAR: '@k-share/pictures/PICTURES_CLEAR',
};
