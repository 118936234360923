import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Text, Picture, Button } from 'views/components';
import { handleFirebaseTracking } from 'utils/analyticsTracking';
import { IPictureUpload, IPictures } from 'state/modules/pictures/reducers';
import { uiOperations, picturesOperations } from 'state/modules';
import './Dropzone.scss';

interface Props {
  userId: string;
  firstname: string;
  pictures: IPictures;
  picturesLength: number;
  elementsLoading: IPictureUpload[];
  handleNext: any;
}

const maxFilesNumber = 20;

const Dropzone = (props: Props) => {
  const {
    userId, pictures, firstname, handleNext, picturesLength, elementsLoading,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['share-app']);
  const orderedPictures: IPictureUpload[] = Object.values(pictures)
    .sort((a: IPictureUpload, b: IPictureUpload) => {
      return new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime();
    });

  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      dispatch(uiOperations.deleteAppNotification());
      handleFirebaseTracking('select_photos_dropzone');

      if (acceptedFiles.length === 0) {
        return;
      }

      if (acceptedFiles.length > maxFilesNumber) {
        dispatch(picturesOperations.picturesError(new Error(t('Upload.uploadLimitError'))));
      }

      const parsedFiles: IPictureUpload[] = acceptedFiles.map((file: any, key: number) => {
        const extension = file.name.split('.').pop() || '';
        const pictureId = uuidv4();
        const rejected = key >= maxFilesNumber;

        return {
          extension,
          pictureId,
          filename: `${pictureId}.${extension}`,
          previewUrl: URL.createObjectURL(file),
          addedAt: new Date(),
          file,
          userId: '',
          isLoading: false,
          isUploaded: false,
          error: rejected,
          storageUrl: '',
          progress: 0,
        };
      });

      dispatch(picturesOperations.uploadPictures(userId, parsedFiles));
    },
    accept: 'image/*',
  });

  const handleOpenDropzone = () => {
    handleFirebaseTracking('select_photos_btn');
    open();
  };

  return (
    <div className="Dropzone">
      <div className="Dropzone-container">
        {orderedPictures.map((picture: IPictureUpload) => (
          <Picture key={picture.pictureId} picture={picture} userId={userId} />
        ))}

        <div {...getRootProps({ className: 'Dropzone-input' })}>
          <input {...getInputProps({ name: 'photos' })} />
          <div className="Dropzone-btn">
            <Text centered bold size="large" color="gray">+</Text>
            <Text centered upper size="small" color="gray">{t('Upload.placeholder')}</Text>
          </div>
        </div>
      </div>

      <div className="Dropzone-action">
        {picturesLength
          ? <Button primary label={elementsLoading.length ? t('Upload.button_loading') : t('Upload.button', { firstname })} onClick={handleNext} />
          : <Button primary label={t('UploadIntro.btn_select_photos')} onClick={handleOpenDropzone} />
          }
      </div>
    </div>
  );
};

export default Dropzone;
