import { AnyAction } from 'redux';
import { from, of } from 'rxjs';
import {
  catchError, filter, switchMap, map,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import * as actions from './actions';

import { firebaseAnonymousLogin, firebaseCheckLoggedUser } from '../../services/firebase';

export const {
  loginRequest,
  loginOk,
  loginCheck,
  sessionError,
} = actions;

// TODO: type action$
export const userLoginEpic: any = (action$: AnyAction) => {
  return action$.pipe(
    filter(isActionOf(actions.loginRequest)),
    switchMap(() => from(firebaseAnonymousLogin())
      .pipe(
        map(() => actions.authOk()),
        catchError((error: Error) => of(actions.sessionError(error))),
      )),
  );
};

export const userLoginCheck: any = (action$: AnyAction) => {
  return action$.pipe(
    filter(isActionOf(actions.loginCheck)),
    switchMap(() => from(firebaseCheckLoggedUser)
      .pipe(
        map((result: any) => {
          const user = { ...result };

          if (user.ra) {
            user.token = user.ra;
          }

          if (user.ma) {
            user.token = user.ma;
          }

          if (user.xa) {
            user.token = user.xa;
          }

          if (user.ya) {
            user.token = user.ya;
          }

          return actions.loginOk({ user });
        }),
        catchError((error: Error) => of(actions.sessionError(error))),
      )),
  );
};

export const epics = [
  userLoginEpic,
  userLoginCheck,
];
