import { IUserProfile } from '@klikkie/klikkie-schemas/lib/interfaces';
// eslint-disable-next-line import/no-cycle
import fetch from './fetch';

const environment = process.env.REACT_APP_NODE_ENV;

const KLIKKIE_API_BASEURL = `
  https://${environment === 'development' ? 'dev.' : ''}api.klikkie.com
`;

/**
 * get API homepage
 */
export const reqHome = async (): Promise<string> => {
  const endpoint = `${KLIKKIE_API_BASEURL}/`;

  return fetch(endpoint);
};

/**
 * Get user share info
 *
 * @param userId
 * @param idToken
 */
export const getUser = async (userId: string, idToken: string): Promise<IUserProfile> => {
  const endpoint = `${KLIKKIE_API_BASEURL}/users/${userId}/share`;
  const body = {};

  const headers = {
    Authorization: `Bearer ${idToken}`,
  };

  return fetch(endpoint, 'GET', body, headers);
};

/**
 *
 * @param userId
 */
export const createUserNotification = async (
  userId: string,
  anonymousUserId: string,
  idToken: string,
): Promise<any> => {
  const endpoint = `${KLIKKIE_API_BASEURL}/notifications/send`;
  const body = {
    userId,
    senderId: anonymousUserId,
    event: 'share-fulfilled',
  };

  const headers = {
    Authorization: `Bearer ${idToken}`,
  };

  return fetch(endpoint, 'POST', body, headers);
};
