import { createSelector } from 'reselect';

import { RootState } from 'state/rootState.d';
import { IAppMessage } from '@klikkie/klikkie-schemas/lib/interfaces';
import { IUserInterface } from './ui.d';

export const selectUI = (state: RootState): IUserInterface => state.ui;

export const appNotificationsSelector = createSelector(
  selectUI,
  (ui: IUserInterface): IAppMessage => {
    return ui.appNotifications;
  },
);

export const isLoadingSelector = createSelector(
  selectUI,
  (ui: IUserInterface): boolean => {
    return ui.isLoading;
  },
);
