import { createAction, createStandardAction } from 'typesafe-actions';

import { IPictureUpload } from './reducers';
import types from './types';

export const uploadPictures = createAction(
  types.PICTURES_UPLOAD,
  resolve => (userId: string, pictures: IPictureUpload[]) => resolve({ userId, pictures }),
);

export const updatePicture = createAction(
  types.PICTURE_UPDATE,
  resolve => (picture: IPictureUpload) => resolve({ picture }),
);

export const deletePicture = createAction(
  types.PICTURE_DELETE,
  resolve => (userId: string, picture: IPictureUpload) => resolve({ userId, picture }),
);

export const removePicture = createAction(
  types.PICTURE_REMOVE,
  resolve => (pictureId: string) => resolve({ pictureId }),
);

export const clearPictures = createAction(
  types.PICTURES_CLEAR,
  resolve => () => resolve({}),
);

export const pictureUploadError = createAction(
  types.PICTURE_ERROR,
  resolve => (pictureId: string) => resolve({ pictureId }),
);

export const rejectPicture = createAction(
  types.PICTURE_REJECTED,
  resolve => (pictureId: string) => resolve({ pictureId }),
);

export const picturesError = createStandardAction(types.ERROR).map(
  (error: Error) => ({
    error: true,
    payload: error,
  }),
);
