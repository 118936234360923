import React, { Component, ReactElement } from 'react';

interface Props {
  readonly load: Promise<any>;
}

interface State {
  readonly component: any;
}

class LazyLoad extends Component<Props, State> {
  UNSAFE_componentWillMount() {
    const { load } = this.props;

    load
      .then((comp: ReactElement<Props>) => {
        this.setState({ component: comp });
        this.forceUpdate();
      });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state && this.state.component
      ? <this.state.component.default />
      : null;
  }
}

// eslint-disable-next-line react/display-name
export default (dynamicImport: any) => () => (
  <LazyLoad load={dynamicImport()} />
);
