import React, {
  FunctionComponent, memo,
} from 'react';
import routes from 'routes';
import { Route } from 'react-router-dom';

import { Notification } from 'views/components';
import './App.scss';

interface Props {
  readonly loading: boolean;
}

const renderRoutes = () => routes.map((route, idx: number) => {
  const index = idx;

  return (<Route key={index} {...route} />);
});

const App: FunctionComponent<Props> = (props: Props) => {
  const routesArray = renderRoutes();
  const { loading } = props;

  return (
    <div className="app">
      {loading && <div className="loading" />}
      <Notification />
      <div className="main">{routesArray}</div>
    </div>
  );
};

export { App as AppPure };

export default memo(App);
