import { createAction, createStandardAction } from 'typesafe-actions';
import { IStory } from '@klikkie/klikkie-schemas/lib/interfaces';

import types from './types';

export const getStory = createAction(
  types.STORY_GET,
  resolve => (storyId: string, userId: string) => resolve({ storyId, userId }),
);

export const setStory = createAction(
  types.STORY_SET,
  resolve => (story: IStory) => resolve({ story }),
);

export const storiesError = createStandardAction(types.ERROR).map(
  (error: Error) => ({
    error: true,
    payload: error,
  }),
);
