import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import { Observable } from 'rxjs';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const analytics = firebase.analytics();

export const firebaseAnonymousLogin = () => {
  return auth.signInAnonymously();
};

export const firebaseLogin = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const firebaseLogout = () => auth.signOut();

export const getUserBearer = () => {
  if (!auth || !auth.currentUser) {
    return null;
  }

  return auth.currentUser.getIdToken();
};

export const firebaseResetPassword = (email: string) => {
  return auth.sendPasswordResetEmail(email);
};

export const firebaseCheckLoggedUser = new Observable(obs => {
  auth.onAuthStateChanged(user => {
    if (user) {
      obs.next(user);
    }
  });
});

export default firebase;
