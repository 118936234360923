import { AnyAction } from 'redux';
import { from, of } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  catchError, filter, switchMap, mergeMap, map,
} from 'rxjs/operators';

import * as uiActions from 'state/modules/ui/actions';
import * as actions from './actions';


import * as klikkieApi from '../../services/klikkieApi';

export const { getUser, setUser, sendNotification } = actions;

export const getUserProfile: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(actions.getUser)),
    switchMap((action: AnyAction) => from(klikkieApi.getUser(
      action.payload.userId,
      action.payload.token,
    ))
      .pipe(
        mergeMap((result: any) => [actions.setUser(result), uiActions.loadingEnd()]),
        catchError((error: Error) => of(actions.userError(error))),
      )),
  );
};

export const sendUserNotification: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(actions.sendNotification)),
    switchMap((action: AnyAction) => from(
      klikkieApi.createUserNotification(
        action.payload.userId,
        action.payload.anonymousUserId,
        action.payload.token,
      ),
    ).pipe(
      map(() => actions.notificationOk()),
      catchError((error: Error) => of(actions.userNotificationError(error))),
    )),
  );
};

export const epics = [
  getUserProfile,
  sendUserNotification,
];
