import { AnyAction } from 'redux';

import { IUserSession } from './session.d';
import types from './types';

// TODO: Move errors to the user interface reducer if possible
export const sessionInitialState: IUserSession = {
  displayName: '',
  email: '',
  idToken: '',
  isLogged: false,
  refreshToken: '',
  userId: '',
  error: false,
  errorMessage: '',
  createdAt: '',
};


export default (state = sessionInitialState, action: AnyAction): IUserSession => {
  switch (action.type) {
    case types.LOGIN_OK: {
      const { user } = action.payload.signupResponse;
      const { metadata } = user;
      const createdAt = metadata && metadata.creationTime ? metadata.creationTime : '';

      return {
        ...state,
        isLogged: true,
        displayName: user.displayName,
        email: user.email,
        idToken: user.token,
        refreshToken: user.refreshToken,
        userId: user.uid,
        error: false,
        errorMessage: '',
        createdAt,
      };
    }

    case types.ERROR: {
      const { message } = action.payload;

      return {
        ...state,
        error: true,
        errorMessage: message,
      };
    }

    default:
      return state;
  }
};
