import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import user, { userOperations, userInitialState } from './user';
import ui, { uiOperations, uiInitialState } from './ui';
import session, { sessionOperations, sessionInitialState } from './session';
import pictures, { picturesOperations, picturesInitialState } from './pictures';
import stories, { storiesOperations, storiesInitialState } from './stories';

export {
  uiOperations,
  userOperations,
  sessionOperations,
  storiesOperations,
  picturesOperations,
};

export const reducers = combineReducers({
  session,
  stories,
  pictures,
  user,
  ui,
});

export const epics = combineEpics(
  ...userOperations.epics,
  ...uiOperations.epics,
  ...picturesOperations.epics,
  ...sessionOperations.epics,
  ...storiesOperations.epics,
);

export const rootInitialState = {
  user: userInitialState,
  ui: uiInitialState,
  pictures: picturesInitialState,
  session: sessionInitialState,
  stories: storiesInitialState,
};
