import { AnyAction } from 'redux';
import { from, of } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  filter, switchMap, map, mergeMap, catchError,
} from 'rxjs/operators';

import { i18n } from 'state/services';
import firebase from 'state/services/firebase';
import { IPictureUpload } from './reducers';
import * as actions from './actions';

export const {
  uploadPictures,
  updatePicture,
  deletePicture,
  removePicture,
  pictureUploadError,
  clearPictures,
  rejectPicture,
  picturesError,
} = actions;

const uploadFirebasePicture = async (userId: string, picture: IPictureUpload): Promise<any> => {
  const { pictureId, extension, file } = picture;
  const storageRef = firebase.storage().ref(`shared/${userId}/${pictureId}.${extension}`);

  return new Promise(((resolve, reject) => {
    const uploadTask = storageRef.put(file);

    uploadTask.on('state_changed',
      () => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        reject(error);
      },
      () => {
        resolve({
          pictureId,
          isLoading: false,
          isUploaded: true,
        });
      });
  }));
};

const deleteFirebasePicture = async (userId: string, picture: IPictureUpload): Promise<any> => {
  const { pictureId, extension } = picture;
  const pictureRef = firebase.storage().ref(`shared/${userId}/${pictureId}.${extension}`);
  const thumbRef = firebase.storage().ref(`shared/${userId}/thumb_${pictureId}.${extension}`);

  thumbRef.delete();
  return pictureRef.delete();
};

export const uploadUserPictures: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(actions.uploadPictures)),
    switchMap((action: AnyAction) => from(
      action.payload.pictures.map(
        (picture: IPictureUpload) => ({ ...picture, userId: action.payload.userId }),
      ),
    )),
    mergeMap((picture: IPictureUpload) => {
      const { userId } = picture;
      const { pictureId, error, file } = picture;
      const { size } = file;

      // Rejected by the limit size
      if (error) {
        return [actions.rejectPicture(pictureId)];
      }

      // Limit files to 50 MB
      if (size / 1000000 > 50) {
        return [actions.pictureUploadError(pictureId), actions.picturesError(new Error(i18n.getTranslation('share-app:Upload.file_exceeded_size', { size: '50' })))];
      }

      return from(uploadFirebasePicture(userId, picture))
        .pipe(
          map((result) => actions.updatePicture(result)),
          catchError(() => of(actions.pictureUploadError(pictureId))),
        );
    }),
  );
};

export const deleteUserPicture: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(actions.deletePicture)),
    switchMap((action: AnyAction) => {
      const { pictureId } = action.payload.picture;

      return from(deleteFirebasePicture(action.payload.userId, action.payload.picture))
        .pipe(
          map(() => actions.removePicture(pictureId)),
          catchError(() => of(actions.removePicture(pictureId))),
        );
    }),
  );
};

export const epics = [
  uploadUserPictures,
  deleteUserPicture,
];
