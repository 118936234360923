import { createAction } from 'typesafe-actions';

import types from './types';

declare type Status = 'pending' | 'fulfilled' | 'rejected';
declare type MessageType = 'warning' | 'info' | 'success' | 'error';

export const setAppNotification = createAction(
  types.APP_NOTIFICATION_SET,
  resolve => (
    message: string,
    type: MessageType,
    status: Status = 'pending',
  ) => resolve({ message, type, status }),
);

export const deleteAppNotification = createAction(
  types.APP_NOTIFICATION_DELETE,
  resolve => () => resolve(),
);

export const loadingStart = createAction(
  types.LOADING_START,
  resolve => () => resolve(),
);

export const loadingEnd = createAction(
  types.LOADING_END,
  resolve => () => resolve(),
);

export const setLanguage = createAction(
  types.LANGUAGE_SET,
  resolve => (language: any) => resolve({ language }),
);
