/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { handleFirebaseTracking } from 'utils/analyticsTracking';

import './Button.scss';

interface Props {
  label: string;
  primary?: boolean;
  secondary?: boolean;
  centered?: boolean;
  upper?: boolean;
  to?: string;
  onClick?: () => void;
  trackingEvent?: string;
}

const Button = (props: Props) => {
  const {
    primary, secondary, upper, centered, label, to, onClick, trackingEvent,
  } = props;

  const classNames = ['Button'];
  if (primary) classNames.push('is-primary');
  if (secondary) classNames.push('is-secondary');
  if (upper) classNames.push('is-upper');
  if (centered) classNames.push('is-centered');

  const handleLinkClick = () => {
    if (trackingEvent) {
      handleFirebaseTracking(trackingEvent);
    }
  };

  return (
    <div className={classNames.join(' ')}>
      {onClick && (
        <button type="button" onClick={onClick}>
          <span className="Button-label">{label}</span>
        </button>
      )}

      {to && (
        <a href={to} onClick={() => handleLinkClick()} target="_blank">
          <span className="Button-label">{label}</span>
        </a>
      )}
    </div>
  );
};

Button.defaultProps = {
  primary: true,
  secondary: false,
  centered: false,
  upper: false,
  to: undefined,
  onClick: undefined,
  trackingEvent: undefined,
};

export default Button;
