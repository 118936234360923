import { AnyAction } from 'redux';
import { IStoriesCollection } from './stories.d';

import types from './types';

export const storiesInitialState: IStoriesCollection = {};

export default (state = storiesInitialState, action: AnyAction): IStoriesCollection => {
  switch (action.type) {
    case types.STORY_SET: {
      const { story } = action.payload;
      const { id } = story;

      return {
        ...state,
        [id]: {
          ...story,
        },
      };
    }

    default:
      return state;
  }
};
