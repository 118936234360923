import React from 'react';

import './Heading.scss';

type Color = 'primary';

interface Props {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  bold?: boolean;
  color?: Color;
  centered?: boolean;
  style?: any;
  children?: React.Component | string,
}

const Heading = (props: Props) => {
  const {
    h1, h2, h3, bold, color, centered, children, style,
  } = props;

  const classNames = ['Heading'];
  if (bold) classNames.push('is-bold');
  if (centered) classNames.push('is-centered');
  if (color === 'primary') classNames.push('is-primary');

  return (
    <div className={classNames.join(' ')}>
      {h1 && <h1 style={style}>{children}</h1>}
      {h2 && <h2 style={style}>{children}</h2>}
      {h3 && <h3 style={style}>{children}</h3>}
    </div>
  );
};

Heading.defaultProps = {
  h1: false,
  h2: false,
  h3: false,
  bold: false,
  color: undefined,
  centered: false,
  style: undefined,
  children: 'Basic heading',
};

export default Heading;
