/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import './Icon.scss';

interface Props {
  id?: string;
  icon: string;
  small?: boolean;
  inverse?: boolean;
  secondary?: boolean;
  onClick?: () => void;
}

const Icon = (props: Props) => {
  const {
    id, icon, small, secondary, inverse, onClick,
  } = props;

  const classNames = ['Icon', `${icon}`];
  if (secondary) classNames.push('is-secondary');
  if (inverse) classNames.push('is-inverse');
  if (small) classNames.push('is-small');

  return (
    <div id={id} className={classNames.join(' ')} onClick={onClick} />
  );
};

Icon.defaultProps = {
  id: '',
  small: false,
  inverse: false,
  secondary: false,
  onClick: () => {},
};

export default Icon;
