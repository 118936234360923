import { createSelector } from 'reselect';

import { RootState } from 'state/rootState.d';
import { UserProfileShareI } from './user.d';

const selectUser = (state: RootState): UserProfileShareI => state.user;

export const userSelector = createSelector(
  selectUser,
  (user: UserProfileShareI): UserProfileShareI => user,
);

export const userIdSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.userId,
);

export const firstnameSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.name,
);


export const surnameSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.surname,
);

export const mgmSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.mgm,
);

export const userErrorSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.error,
);

export const countryCodeSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => user.countryCode,
);

export const languageSelector = createSelector(
  userSelector,
  (user: UserProfileShareI) => {
    const { countryCode } = user;

    switch (countryCode) {
      case 'BE':
        return 'be-BE';

      case 'FR':
        return 'fr-FR';

      case 'NL':
        return 'nl-NL';

      default:
        return 'en-EN';
    }
  },
);
