import { AnyAction } from 'redux';
import { from, of } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import {
  filter, switchMap, map, catchError,
} from 'rxjs/operators';

import firebase from 'state/services/firebase';
import * as actions from './actions';
import { IStoryExtended } from './stories.d';

export const {
  getStory,
  setStory,
} = actions;


const getFirebaseStory = async (storyId: string, userId: string): Promise<any> => {
  const storyRef = firebase.storage().ref(`stories/shared/${userId}/${storyId}`);

  const story: IStoryExtended = {
    id: storyId,
    userId,
    files: [],
  };

  let filesOrder: string[] = [];
  const pages: any = {};

  const storyPages = await storyRef.listAll();
  if (storyPages) {
    await Promise.all(storyPages.items.map(async pageRef => {
      if (!pageRef.name.includes('thumb_') && pageRef.name !== 'order.json') {
        const url = await pageRef.getDownloadURL();
        const fileNameSplit = pageRef.name.split('.');
        const pageId = fileNameSplit[0];

        pages[pageId] = {
          uri: url,
          fileName: pageRef.name,
          pageId,
        };
      }

      if (pageRef.name === 'order.json') {
        const fileUrl = await pageRef.getDownloadURL();
        const response = await fetch(fileUrl);

        const json = await response.text();
        const res = JSON.parse(json);
        if (res) {
          const { order = [] } = res;
          filesOrder = order;
        }
      }
    }));
  }

  filesOrder.forEach((pageId: string) => {
    if (pages[pageId]) {
      if (story.files) {
        story.files.push(pages[pageId]);
      }
    }
  });

  // const newStory = {
  //   ...story,
  //   files: story.files ? story.files.sort((a: IStoryPageExtended, b: IStoryPageExtended) => {
  //     const fileNameA = a.fileName || '';
  //     const fileNameB = b.fileName || '';

  //     const bSplit = fileNameB.split('-');
  //     const aSplit = fileNameA.split('-');
  //     const indexA = parseInt(aSplit[0], 10);
  //     const indexB = parseInt(bSplit[0], 10);

  //     if (indexA && indexB) {
  //       if (indexA < indexB) {
  //         return -1;
  //       }
  //       if (indexA > indexB) {
  //         return 1;
  //       }
  //     }

  //     return 0;
  //   }) : [],
  // };

  return story;
};


export const getUserStory: any = (action$: any) => {
  return action$.pipe(
    filter(isActionOf(actions.getStory)),
    switchMap((action: AnyAction) => from(
      getFirebaseStory(action.payload.storyId, action.payload.userId),
    )
      .pipe(
        map((story: IStoryExtended) => actions.setStory(story)),
        catchError((error) => of(actions.storiesError(error))),
      )),
  );
};

export const epics = [
  getUserStory,
];
