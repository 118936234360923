import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from 'views/components';
import { handleFirebaseTracking } from 'utils/analyticsTracking';
import { deletePicture } from 'state/modules/pictures/operations';
import { IPictureUpload } from 'state/modules/pictures/reducers';
import './Picture.scss';

interface Props {
  userId: string;
  picture: IPictureUpload;
}

const Picture = (props: Props) => {
  const { userId, picture } = props;
  const { error, isUploaded } = picture;
  const { t } = useTranslation(['share-app']);
  const dispatch = useDispatch();

  const onDelete = () => {
    handleFirebaseTracking('delete_picture');
    dispatch(deletePicture(userId, picture));
  };

  return (
    <div key={picture.pictureId} className="Picture-preview">
      <div className="Picture-container">
        <img className="image" src={picture.previewUrl} alt={picture.pictureId} />
      </div>
      {error && (
        <div className="Picture-error">
          <Text size="small">{t('Upload.photo_error')}</Text>
        </div>
      )}
      {isUploaded && (
        <div className="Picture-success">
          <Icon inverse icon="ion-ios-checkmark-outline" />
        </div>
      )}
      <div className="Picture-delete">
        <Icon id="deletePicture" inverse small icon="ion-close-circled" onClick={onDelete} />
      </div>
    </div>
  );
};

export default Picture;
