import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import TranslationProvider from 'views/containers/Translation/TranslationProvider';
import store, { persistor } from './state/store';
import AppConnect from './views/containers/App/App';

import 'normalize.css';

window.dataLayer = window.dataLayer || [];

declare global {
  interface Window {
    dataLayer: any[];
    chckt: any;
  }
}
const RootHTML = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <TranslationProvider initialLanguage="nl-NL">
          <AppConnect loading />
        </TranslationProvider>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
);

render(<RootHTML />, document.getElementById('root'));
