import { IUser } from '@klikkie/klikkie-schemas/lib/interfaces';
import { createAction, createStandardAction } from 'typesafe-actions';

import types from './types';

export const getUser = createAction(
  types.USER_GET,
  resolve => (userId: string, token: string) => resolve({ userId, token }),
);

export const setUser = createAction(
  types.USER_SET,
  resolve => (user: IUser) => resolve({ user }),
);

export const sendNotification = createAction(
  types.USER_SEND_NOTIFICATION,
  resolve => (
    userId: string,
    anonymousUserId: string,
    token: string,
  ) => resolve({ userId, anonymousUserId, token }),
);

export const notificationOk = createAction(
  types.USER_NOTIFICATION_OK,
  resolve => () => resolve(),
);

export const userNotificationError = createStandardAction(types.USER_NOTIFICATION_ERROR).map(
  (error: Error) => ({
    error: true,
    payload: error,
  }),
);

export const userError = createStandardAction(types.ERROR).map(
  (error: Error) => ({
    error: true,
    payload: error,
  }),
);
