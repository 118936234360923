/* eslint-disable import/no-cycle */
import isomorphicFetch from 'isomorphic-fetch';

import { i18n } from '.';

export type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export interface IRestRequestConfig {
  method?: RestMethod;
  headers?: any;
  body?: any;
  cache?: object;
}

export const buildRequestObject = (requestObj: IRestRequestConfig): IRestRequestConfig => {
  const {
    method,
    headers,
    body,
    cache,
  } = requestObj;

  const defaultheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const fetchQueryObj: IRestRequestConfig = {};

  //  method
  fetchQueryObj.method = typeof method === 'undefined'
    ? 'GET'
    : method;

  // headers
  fetchQueryObj.headers = typeof headers === 'undefined'
    ? defaultheaders
    : { ...defaultheaders, ...headers };

  //  body
  if (typeof body !== 'undefined') {
    fetchQueryObj.body = JSON.stringify(body);
  }

  // cache
  if (typeof cache !== 'undefined') {
    fetchQueryObj.cache = cache;
  }

  return fetchQueryObj;
};

export default (url: string, method: RestMethod = 'GET', body?: any, headers?: object): Promise<any> => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  // debugger;

  const options: RequestInit = {
    // eslint-disable-next-line no-nested-ternary
    body: method !== 'GET'
      ? (typeof body === 'object' ? JSON.stringify(body) : body)
      : null,
    headers: { ...defaultHeaders, ...headers },
    method,
  };

  return isomorphicFetch(url, options)
    .then((response: Response) => {
      if (response.status >= 400) {
        const errorMessage = response.statusText
          ? response.statusText
          : i18n.getTranslation('serverError.message');

        throw new Error(errorMessage);
      }
      return response;
    })
    .then((response: Response) => {
      if (response) {
        if (response.status === 204) {
          return response;
        }

        return response.json();
      }

      return response;
    })
    .then(result => result.data || result);
};
