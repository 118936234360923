import { AnyAction, Dispatch } from 'redux';

import * as uiActions from 'state/modules/ui/actions';
import userTypes from '../modules/user/types';

// eslint-disable-next-line max-len
const errorMiddleware = () => (next: Dispatch<AnyAction>) => <A extends AnyAction>(action: A) => {
  if (action.error) {
    switch (action.type) {
      case userTypes.ERROR:
        return next(action);
      default:
        // eslint-disable-next-line no-console
        console.log(
          `%c ${action.type} error: ${action.payload}`,
          'background: #222; color: red; font-weight: 700;',
        );
        return next(uiActions.setAppNotification(action.payload.message, 'error'));
    }
  }
  return next(action);
};

export default errorMiddleware;
