/* eslint-disable react/no-danger */
import React from 'react';

import './Text.scss';

type Margin = 'large';
type Size = 'large' | 'medium' | 'small';
type Color = 'gray' | 'white';

interface Props {
  centered?: boolean;
  bold?: boolean;
  upper?: boolean;
  color?: Color;
  size?: Size;
  margin?: Margin;
  children?: any | string;
}

const Text = (props: Props) => {
  const {
    centered, bold, upper, color, size, children, margin,
  } = props;

  const classNames = ['Text'];
  if (bold) classNames.push('is-bold');
  if (upper) classNames.push('is-upper');
  if (centered) classNames.push('is-centered');
  if (size === 'small') classNames.push('is-small');
  if (size === 'medium') classNames.push('is-medium');
  if (size === 'large') classNames.push('is-large');
  if (color === 'gray') classNames.push('is-gray');
  if (color === 'white') classNames.push('is-white');
  if (margin === 'large') classNames.push('margin-large');

  return (
    <div className={classNames.join(' ')}>
      <p dangerouslySetInnerHTML={{ __html: children }} />
    </div>
  );
};

Text.defaultProps = {
  centered: false,
  bold: false,
  upper: false,
  color: undefined,
  size: undefined,
  margin: undefined,
  children: '',
};

export default Text;
