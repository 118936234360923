import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'views/components';
import { appNotificationsSelector } from 'state/modules/ui/selectors';
import './Notification.scss';

const Notification = () => {
  const notification = useSelector(appNotificationsSelector);

  if (notification.status === 'fulfilled') return null;

  return (
    <div className="Notification">
      <Text centered>{notification.message}</Text>
    </div>
  );
};

export default Notification;
