/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { RootState } from 'state/rootState.d';
import { IPictures } from './reducers';

const selectPictures = (state: RootState): IPictures => state.pictures;

export const picturesSelector = createSelector(
  selectPictures,
  (pictures: IPictures): IPictures => pictures,
);
