import { AnyAction } from 'redux';
import { UserProfileShareI } from './user.d';

import types from './types';

export const userInitialState: UserProfileShareI = {
  userId: '',
  name: '',
  surname: '',
  mgm: '',
  countryCode: '',
  error: false,
};

export default (state = userInitialState, action: AnyAction): UserProfileShareI => {
  switch (action.type) {
    case types.USER_SET: {
      const { user } = action.payload;

      return {
        ...state,
        ...user,
        error: false,
      };
    }
    case types.ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
