/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { RootState } from 'state/rootState.d';
import { IStoryExtended, IStoriesCollection } from './stories.d';

const selectStories = (state: RootState): IStoriesCollection => state.stories;

export const storySelector = (storyId: string) => createSelector(
  selectStories,
  (stories: IStoriesCollection): IStoryExtended => stories[storyId],
);
