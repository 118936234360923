import { RouteProps } from 'react-router';

import { lazyLoad } from 'views/enhancers';

const routes: RouteProps[] = [
  {
    component: lazyLoad(() => import('views/pages/UploadPage/UploadPage')),
    exact: true,
    path: '/',
  },
  {
    component: lazyLoad(() => import('views/pages/Story/Story')),
    exact: true,
    path: '/story/:userId/:storyId',
  },
  // {
  //   component: lazyLoad(() => import('views/pages/HomePage/HomePage')),
  //   exact: false,
  //   path: '/:anything',
  // },
];

export default routes;
