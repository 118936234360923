/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const nextArrow = require('views/assets/images/icons/next-arrow.png');

interface NextArrowProps {
  display?: boolean;
  nextPage: () => void;
}

const NextArrow = (props: NextArrowProps) => {
  const { display, nextPage } = props;

  if (!display) return null;

  return (
    <div className="slick-arrow next-arrow" onClick={nextPage}>
      <img src={nextArrow} alt="next-arrow" />
    </div>
  );
};

NextArrow.defaultProps = {
  display: true,
};

export default NextArrow;
