// export { default as apiMiddleware } from './apiMiddleware';
import reduxThunk from 'redux-thunk';

import epicMiddleware from './epicMiddleware';
import errorMiddleware from './errorMiddleware';
import loggerMiddleware from './loggerMiddleware';
import measureMiddleware from './measureMiddleware';

// Due to redux observables need to export the epic middleware
// to be run after initializing the middelwares.
export const middlewares = [
  reduxThunk,
  measureMiddleware,
  loggerMiddleware,
  errorMiddleware,
  epicMiddleware,
];

export {
  reduxThunk,
  measureMiddleware,
  loggerMiddleware,
  errorMiddleware,
  epicMiddleware,
};
