import { AnyAction } from 'redux';

import types from './types';

export interface IPictureUpload {
  pictureId: string;
  extension: string;
  filename: string;
  userId: string;
  file: File;
  isUploaded: boolean;
  isLoading: boolean;
  error: boolean;
  storageUrl: string;
  previewUrl: string;
  progress: number;
  addedAt: string | number | Date;
}


export interface IPictures {
  [pictureId: string]: IPictureUpload;
}

export const picturesInitialState: IPictures = {};

export default (state = picturesInitialState, action: AnyAction): IPictures => {
  switch (action.type) {
    case types.PICTURES_UPLOAD: {
      const { pictures } = action.payload;
      const newPictures = pictures.reduce((acc: any, picture: IPictureUpload) => {
        acc[picture.pictureId] = {
          ...picture,
          isUploaded: false,
          isLoading: true,
          error: false,
        };

        return acc;
      }, {});

      return {
        ...state,
        ...newPictures,
      };
    }

    case types.PICTURE_UPDATE: {
      const { picture } = action.payload;
      const { pictureId } = picture;

      const newPictures = {
        ...state,
        [pictureId]: {
          ...state[pictureId],
          ...picture,
        },
      };


      return {
        ...newPictures,
      };
    }

    case types.PICTURE_REMOVE: {
      const { pictureId } = action.payload;

      const newPictures = { ...state };
      delete newPictures[pictureId];

      return {
        ...newPictures,
      };
    }

    case types.PICTURE_REJECTED:
    case types.PICTURE_ERROR: {
      const { pictureId } = action.payload;

      const pictureUpdate = {
        pictureId,
        isLoading: false,
        isUploaded: false,
        error: true,
      };

      return {
        ...state,
        [pictureId]: {
          ...state[pictureId],
          ...pictureUpdate,
        },
      };
    }

    case types.PICTURES_CLEAR: {
      return {};
    }

    default:
      return state;
  }
};
