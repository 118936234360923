import React from 'react';

import './ProgressBar.scss';

interface Props {
  progress: number;
}

const ProgressBar = (props: Props) => {
  const { progress } = props;
  const style = { width: `${progress}%` };

  return (
    <div className="Progress-bar">
      <div className="Progress-value" style={style} />
    </div>
  );
};

export default ProgressBar;
